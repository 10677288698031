const versionNumber = 0;
const versionDate = '2025.04.03 04:29';
export const environment = {
  apiUrl: 'https://api.platin-beta.inwe.pl',
  envName: "beta",
  feVersion: `${versionDate}`,
  beVersion: 'DATE',
  inProgress: false,
  storageHashKey: "ZZlaiowd7p0a8n3mceaPM:ADANYap;mddASda;pw9m3p0w;fesszs",
  isBeta: true
};

